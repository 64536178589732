import React from "react";
import Header from "../components/header/header.js";
import RevenueManagementIcon from "../assets/img/svg/revenue-management.svg";

const RevenueManagement = () => {
  return (
    <>
      <Header
        title="Revenue Management Avanzato"
        description={`Ti consente di gestire in modo rapido ed automatico tutti i portali di distribuzione online da un solo back-office.`}
        categoryIcon={{
          icon: RevenueManagementIcon,
          alt: "Revenue Management Banner",
        }}
        metaDescription="Ti consente di gestire in modo rapido ed automatico tutti i portali di distribuzione online da un solo back-office."
      />
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <span className="green bold">
              Tramite Book@Me sarà possibile creare diverse BAR (Best Available
              Rate) e selezionare la BAR desiderata per uno specifico giorno
              effettuando un semplice click.
            </span>
            <p className="description">
              <br />
              Nel calendario tariffario sono visualizzati tutti i dati relativi
              alla percentuale di occupazione, il RevPAR (Revenue per available
              room) e l'average rate (prezzo medio per camera venduta). Tutti
              questi strumenti faciliteranno la scelta di una tariffa piuttosto
              che un'altra con molta semplicità.
            </p>
          </div>
        </div>
      </div>
      <div className="section" />
      <div className="container">
        <div className="columns">
          <div className="column">
            <span className="green">API (disponibili per ogni PMS)</span>
          </div>
          <div className="column">
            <p className="description">
            Link per effettuare il checkIN online inviato automaticamente al momento del download della prenotazione dal booking online al vs. PMS 
            </p>
            <br/>
            <br/>
            <p className="description">
            Link generato anche per le prenotazioni inserite nel gestionale
            </p>
            <br/>
            <br/>
            <p className="description">
            Possibilità di scaricare i dati dei check-in online automaticamente nel vs. PMS
            </p>
          </div>
        </div>        
      </div>
      <div className="section" />
    </>
  );
};

export default RevenueManagement;
